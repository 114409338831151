import React from "react";
import SeoTags from "../components/seo";
import CookiePolicyEnglish from "../components/cookiePolicyEnglish";
import CookiePolicySerbian from "../components/cookiePolicySerbian";
import Header from "../components/header";
function Cookies(props) {
  const { lang } = props;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return (
    <>
      <SeoTags
        title="Politika o kolačićima"
        description="Pogledajte Politiku o kolačićima Beograd Inndex-a."
        path="politika-o-kolacicima/"
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo="/"
              firstLine={process.env.REACT_APP_CITY.translate()}
              secondLine={"Politika o kolačićima".translate()}
              icon={
                <i className="bi bi-file-earmark-text me-2 icon-primary"></i>
              }
            />
          </div>
        </div>
        <div className="container-md my-5">
          <div className="row">
            <div className="m-auto col-lg-8 policy-page">
              {lang === "sr" && <CookiePolicySerbian />}
              {lang === "en" && <CookiePolicyEnglish />}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Cookies;
