import React, { useState, useEffect } from "react";
import SeoTags from "../components/seo";
import Header from "../components/header";
import Buttons from "../components/object/buttonsMap";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import PromoApp from "../components/promoApp";
import SidebarLatestNews from "../components/sidebarLatestNews";
import SidebarLatestEvents from "../components/sidebarLatestEvents";

function ContactMap(props) {
  const [data, setData] = useState(null);
  const [center, setCenter] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [drivingState, setDrivingState] = useState(null);
  const [walkingState, setWalkingState] = useState(null);
  const [myLocationState, setMyLocationState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lang, locale } = props;

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setDirectionsResponse(null);
    setDistance("");
    setDrivingState(null);
    setWalkingState(null);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: "inndex-media-doo",
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_object.php", requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        setData(actualData);
        setCenter({
          lat: parseFloat(actualData.lat),
          lng: parseFloat(actualData.lng),
        });
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setOrigin({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setDestination({
              lat: parseFloat(actualData.lat),
              lng: parseFloat(actualData.lng),
            });
          },
          function (error) {
            setDestination({
              lat: parseFloat(actualData.lat),
              lng: parseFloat(actualData.lng),
            });
            setDrivingState("disabled");
            setWalkingState("disabled");
            setMyLocationState("disabled");
          }
        );
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  async function calculateRoute(mode) {
    let travelMode;
    if (mode === "driving") {
      //eslint-disable-next-line no-undef
      travelMode = google.maps.TravelMode.DRIVING;
      if (drivingState !== "disabled") {
        setDrivingState("active");
      }
      if (walkingState !== "disabled") {
        setWalkingState(null);
      }
    } else {
      //eslint-disable-next-line no-undef
      travelMode = google.maps.TravelMode.WALKING;
      if (drivingState !== "disabled") {
        setDrivingState(null);
      }
      if (walkingState !== "disabled") {
        setWalkingState("active");
      }
    }
    //eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: travelMode,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
  }

  function handleMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      setOrigin({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      map.panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }

  function handleDestination() {
    map.panTo(destination);
  }

  function clearRoute() {
    // setDirectionsResponse(null);
    setDistance("");
  }

  return (
    <>
      <SeoTags
        title={!loading && "Kontakt - " + data.name_sr + " - Mapa"}
        description={
          !loading &&
          data.text_sr
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/(\r\n|\n|\r)/gm, "")
            .split(" ")
            .slice(0, 15)
            .join(" ") + "..."
        }
        path="kontakt/map/"
        type="article"
        image={process.env.REACT_APP_RESOURCES + (!loading && data.filename)}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo={"/kontakt"}
              firstLine={"Kontakt".translate()}
              secondLine={!loading && data["name_" + lang] + " " + distance}
              loading={loading}
              icon={<i className="bi bi-info-circle me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8">
              <Buttons
                onClickRoute={calculateRoute}
                drivingState={drivingState}
                walkingState={walkingState}
                onClickMyLocation={handleMyLocation}
                myLocationState={myLocationState}
                onClickDestination={handleDestination}
                loading={loading}
              />

              <div className="row g-0 p-3 map-container">
                <div className="col-md col-overflow">
                  <div className="row-md mb-3 h-100">
                    {data && isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          //zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        <Marker
                          position={center}
                          animation={window.google.maps.Animation.DROP}
                        />
                        {directionsResponse && (
                          <DirectionsRenderer directions={directionsResponse} />
                        )}
                        <></>
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-column mt-3 pb-3">
                <SidebarLatestNews
                  lang={lang}
                  locale={locale}
                  showSubheader={true}
                  newsSingle="0"
                />
                <SidebarLatestEvents
                  lang={lang}
                  locale={locale}
                  showSubheader={true}
                />
                <PromoApp sidebar={true} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ContactMap;
