import React, { useState, useEffect, useRef } from "react";
import SeoTags from "../components/seo";
import Header from "../components/header";
import Buttons from "../components/object/buttons";
import MainPhoto from "../components/object/mainPhoto";
import GalleryPhoto from "../components/object/galleryPhoto";
import Contact from "../components/object/contact";
import Text from "../components/object/text";
import ShareModal from "../components/shareModal";
import CommentModal from "../components/commentModal";
import RateModal from "../components/object/rateModal";
import Comment from "../components/comment";
import PromoApp from "../components/promoApp";
import SubHeader from "../components/subHeader";
import ConfirmationDialog from "../components/confirmationDialog";
import SidebarLatestNews from "../components/sidebarLatestNews";
import SidebarLatestEvents from "../components/sidebarLatestEvents";

function ContactPage(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [error, setError] = useState(null);
  const { lang, addToast, locale } = props;
  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const [showComment, setShowComment] = useState(false);
  const [commentError, setCommentError] = useState(null);
  const [showRate, setShowRate] = useState(false);
  const [ratingError, setRatingError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const scrollBox = useRef(null);

  const updateLikes = (newLikes) => {
    let newData = { ...data };
    newData.object_likes = newLikes;
    setData(newData);
  };

  const updateRating = (newRating) => {
    let newData = { ...data };
    newData.object_rating = newRating;
    setData(newData);
  };

  const handleLike = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        object_id: data.location_id,
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "add_like.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.response_code === "200") {
          addToast("Uspešno", data.response, "success");
          updateLikes(data.new_likes);
          setShowConfirm(false);
        } else {
          addToast("Greška!", data.response, "error");
          setShowConfirm(false);
        }
      });
  };

  const handleSubmitComment = (name, email, comment, privacy) => {
    setCommentError(null);
    setCommentLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        object_id: data.location_id,
        comment_name: name,
        comment_email: email,
        comment_text: comment,
        privacy_policy: privacy,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "add_comment.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response_code === "200") {
          setCommentLoading(false);
          setTimeout(function () {
            handleCloseComment();
            addToast("Uspešno", data.response, "success");
          }, 300);
        } else {
          setCommentLoading(false);
          setCommentError(data.response.translate());
        }
      });
  };
  const handleCloseComment = () => {
    setShowComment(false);
    setCommentError(null);
  };

  const handleSubmitRate = (rating) => {
    if (rating === 0) setRatingError("Najmanja ocena je 1.".translate());
    else {
      setRatingError(null);
      setRatingLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          object_id: data.location_id,
          rating: rating,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "add_rating.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.response_code === "200") {
            setRatingLoading(false);
            setTimeout(function () {
              updateRating(data.new_rating);
              handleCloseRate();
              addToast("Uspešno", data.response, "success");
            }, 300);
          } else {
            setRatingLoading(false);
            setRatingError(data.response.translate());
          }
        });
    }
  };

  const handleCloseRate = () => {
    setShowRate(false);
    setRatingError(null);
  };

  const handlePhoto = (photo, imageRef) => {
    let width = Math.round(imageRef.current.clientWidth);
    let scrolled = Math.round(scrollBox.current.scrollLeft);
    let boxPosition = Math.round(scrollBox.current.offsetLeft);
    let itemPosition = Math.round(imageRef.current.offsetLeft);
    let scrollAmount;

    if (itemPosition - scrolled === boxPosition) {
      scrollAmount = width - 2 * width;
    } else if (
      itemPosition - scrolled - width === boxPosition ||
      itemPosition - scrolled - 2 * width === boxPosition
    ) {
      scrollAmount = 0;
    } else {
      scrollAmount = width;
    }

    scrollBox.current.scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: "smooth",
    });
    const singleObject = { ...data };
    singleObject.filename = photo.filename;
    setData(singleObject);
  };

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: "inndex-media-doo",
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_object.php", requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        setData(actualData);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <SeoTags
        title={!loading && "Kontakt - " + data.name_sr}
        description={
          !loading &&
          data.text_sr
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/(\r\n|\n|\r)/gm, "")
            .split(" ")
            .slice(0, 15)
            .join(" ") + "..."
        }
        path="kontakt/"
        type="article"
        image={process.env.REACT_APP_RESOURCES + (!loading && data.filename)}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo={"/"}
              firstLine={"Kontakt".translate()}
              secondLine={!loading && data["name_" + lang]}
              loading={loading}
              icon={<i className="bi bi-info-circle me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8">
              <Buttons
                onClickLike={() => {
                  setShowConfirm(true);
                }}
                onClickShare={() => {
                  setShowShare(true);
                }}
                onClickComment={() => {
                  setShowComment(true);
                }}
                onClickRate={() => {
                  setShowRate(true);
                }}
                data={!loading && data}
                loading={loading}
              />
              <div className="row g-0 p-3">
                <div className="col-md">
                  <div className="row-md mb-3">
                    <MainPhoto
                      key={!loading && data.location_id}
                      object={!loading && data}
                      lang={lang}
                      loading={loading}
                    />
                  </div>
                  <div
                    ref={scrollBox}
                    className="row g-3 mb-3 h-scroll scroll-snap-parent"
                  >
                    {loading && (
                      <>
                        <GalleryPhoto loading={loading} />
                        <GalleryPhoto loading={loading} />
                        <GalleryPhoto loading={loading} />
                        <GalleryPhoto loading={loading} />
                      </>
                    )}
                    {!loading &&
                      data &&
                      data.photos.length > 1 &&
                      data.photos.map((photo) => (
                        <GalleryPhoto
                          key={photo.photo_id}
                          onPhoto={handlePhoto}
                          photo={photo}
                          lang={lang}
                          loading={loading}
                        />
                      ))}
                  </div>
                  <div className="row g-3 mb-3">
                    <Text
                      text={!loading && data}
                      lang={lang}
                      loading={loading}
                    />
                  </div>
                  <SubHeader
                    className="mb-3"
                    title={"Kontakt informacije".translate()}
                    h2={true}
                    loading={loading}
                  />
                  <div className="row g-3 mb-3 contacts">
                    {loading && (
                      <>
                        <Contact loading={loading} />
                        <Contact loading={loading} />
                        <Contact loading={loading} />
                        <Contact loading={loading} />
                        <Contact loading={loading} />
                        <Contact loading={loading} />
                      </>
                    )}
                    {!loading &&
                      data &&
                      data.contacts.map((contact) => (
                        <Contact
                          key={contact.contact_id}
                          contact={contact}
                          lang={lang}
                          loading={loading}
                        />
                      ))}
                  </div>
                  {!loading && data && (
                    <>
                      {data && (
                        <SubHeader
                          className="mb-3"
                          title={
                            "Komentari".translate() +
                            " (" +
                            data.object_comments +
                            ")"
                          }
                          h2={true}
                          loading={loading}
                        />
                      )}
                      <div className="row ps-3 pe-3">
                        {data && data.object_comments !== "0" ? (
                          data.comments.map((comment, i, arr) => (
                            <Comment
                              key={comment.id}
                              commentName={comment.comment_name}
                              commentText={comment.comment_text}
                              commentDate={comment.comment_date}
                              i={i}
                              arr={arr}
                              locale={locale}
                            />
                          ))
                        ) : (
                          <div className="col-md-12 p-3">
                            <div className="comment-text">
                              {"Trenutno nema komentara.".translate()}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-column mt-3 pb-3">
                <SidebarLatestNews
                  lang={lang}
                  locale={locale}
                  showSubheader={true}
                  newsSingle="0"
                />
                <SidebarLatestEvents
                  lang={lang}
                  locale={locale}
                  showSubheader={true}
                />
                <PromoApp sidebar={true} />
              </div>
            </div>
          </div>
          <ShareModal
            show={showShare}
            onCloseShare={handleCloseShare}
            body={data && data["text_" + lang]}
            subject={data && data["name_" + lang]}
            media={data && process.env.REACT_APP_RESOURCES + data.filename}
          />
          <CommentModal
            lang={lang}
            show={showComment}
            onSubmitComment={handleSubmitComment}
            onCloseComment={handleCloseComment}
            commentLoading={commentLoading}
            commentError={commentError}
          />
          <RateModal
            show={showRate}
            onSubmitRate={handleSubmitRate}
            onCloseRate={handleCloseRate}
            ratingLoading={ratingLoading}
            ratingError={ratingError}
          />
          <ConfirmationDialog
            icon={<i className="bi bi-hand-thumbs-up-fill icon-primary"></i>}
            title={"Sviđanje".translate()}
            body={
              !loading &&
              "Da li želite da označite da vam se sviđa lokacija".translate() +
                " " +
                data["name_" + lang] +
                "?"
            }
            confirmButton={"Da".translate()}
            closeButton={"Ne".translate()}
            show={showConfirm}
            onCloseDialog={() => {
              setShowConfirm(false);
            }}
            onConfirmDialog={handleLike}
          />
        </div>
      </main>
    </>
  );
}

export default ContactPage;
