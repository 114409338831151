import React, { useState, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import NavBar from "./components/navbar";
import "./App.css";
import Objects from "./pages/objects";
import Object from "./pages/object";
import ObjectMap from "./pages/objectMap";
import Home from "./pages/home";
import SearchResults from "./pages/searchResults";
import Events from "./pages/events";
import EventsSingle from "./pages/eventsSingle";
import News from "./pages/news";
import NewsSingle from "./pages/newsSingle";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import SiteFooter from "./components/siteFooter";
import PrivacyPolicy from "./pages/privacyPolicy";
import ScrollTop from "./helpers/scrollToTop";
import Locations from "./pages/locations";
import CookieConsent from "react-cookie-consent";
import Cookies from "./pages/cookies";
import ContactPage from "./pages/contact";
import ContactMap from "./pages/contactMap";
import NotFound from "./pages/notFound";

const languages = require("./helpers/languages.json");
const translation = require("./helpers/translation.json");

const language = () => {
  let lang = localStorage.getItem("language");
  return lang ? lang : process.env.REACT_APP_DEFAULT_LANGUAGE;
};

const locale = () => {
  let locale = localStorage.getItem("locale");
  return locale ? locale : process.env.REACT_APP_DEFAULT_LOCALE;
};

String.prototype.translate = function () {
  return translation[language()][this] === undefined
    ? this
    : translation[language()][this];
};

function App() {
  const deleteToast = (index) => {
    let newToasts = [...toasts];
    newToasts[index].show = false;
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
  };

  const addToast = (title, text, type) => {
    if (!type) type = "info";
    let newToasts = [
      ...toasts,
      { title: title, text: text, type: type, show: true },
    ];
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
  };

  const navigate = useNavigate();

  const handleChangeLang = (selectedLang) => {
    if (selectedLang !== language()) {
      localStorage.setItem("language", selectedLang);
      languages.map((language) => {
        if (language.shortName === selectedLang) {
          localStorage.setItem("locale", language.locale);
        }
      });
      navigate(0);
    }
  };

  const [toasts, setToasts] = useState(null);

  useEffect(() => {
    let loadedToasts = JSON.parse(localStorage.getItem("toasts"));
    let newToasts = [];
    loadedToasts &&
      loadedToasts.map((loadedToast) => {
        if (loadedToast.show) {
          newToasts = [...newToasts, loadedToast];
        }
      });
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
  }, []);

  return (
    <HelmetProvider>
      <CookieConsent
        location="bottom"
        buttonText={"Prihvatam".translate()}
        cookieName="InndexCookieConsentV1"
        style={{ background: "#212529" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        {"Korišćenjem ove stranice prihvatate našu Politiku o kolačićima (eng. Cookie policy). Više detalja pogledajte na strani ".translate()}
        <Link to="/politika-o-kolacicima" className="cookie-link">
          {"Politika o kolačićima".translate()}
        </Link>
      </CookieConsent>
      <NavBar
        lang={language()}
        onChangeLang={handleChangeLang}
        languages={languages}
      />

      <Routes>
        <Route
          path="/"
          element={<Home lang={language()} locale={locale()} />}
        />
        <Route
          path="/najave"
          element={<Events lang={language()} locale={locale()} />}
        />
        <Route
          path="/najave/:category"
          element={<Events lang={language()} locale={locale()} />}
        />
        <Route
          path="/najave/:category/:eventsSingle"
          element={
            <EventsSingle
              addToast={addToast}
              lang={language()}
              locale={locale()}
            />
          }
        />
        <Route
          path="/vesti"
          element={<News lang={language()} locale={locale()} />}
        />
        <Route
          path="/vesti/:newsSingle"
          element={
            <NewsSingle
              addToast={addToast}
              lang={language()}
              locale={locale()}
            />
          }
        />
        <Route
          path="/lokacije"
          element={<Locations lang={language()} locale={locale()} />}
        />
        <Route
          path="/lokacije/:group/:subgroup"
          element={<Objects lang={language()} />}
        />
        <Route
          path="/lokacije/:group/:subgroup/:object"
          element={
            <Object lang={language()} addToast={addToast} locale={locale()} />
          }
        />
        <Route
          path="/lokacije/:group/:subgroup/:object/map"
          element={<ObjectMap lang={language()} />}
        />
        <Route
          path="/rezultati-pretrage"
          element={<SearchResults lang={language()} locale={locale()} />}
        />
        <Route
          path="/politika-privatnosti"
          element={<PrivacyPolicy lang={language()} />}
        />
        <Route
          path="/politika-o-kolacicima"
          element={<Cookies lang={language()} />}
        />
        <Route
          path="/kontakt"
          element={
            <ContactPage
              lang={language()}
              addToast={addToast}
              locale={locale()}
            />
          }
        />
        <Route
          path="/kontakt/map"
          element={
            <ContactMap
              lang={language()}
              addToast={addToast}
              locale={locale()}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollTop />
      <SiteFooter />
      <ToastContainer
        containerPosition="fixed"
        className="p-3"
        position="bottom-start"
      >
        {toasts &&
          toasts.map((toast, i) => (
            <Toast
              key={"toast-" + i}
              show={toast.show}
              animation={true}
              onClose={() => deleteToast(i)}
            >
              {toast.type === "error" && (
                <Toast.Header className="bg-danger bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-exclamation-triangle-fill me-1 text-danger"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              {toast.type === "success" && (
                <Toast.Header className="bg-success bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-check-circle-fill me-1 text-success"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              {toast.type === "info" && (
                <Toast.Header className="bg-primary bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-info-circle-fill me-1 text-primary"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              <Toast.Body>{toast.text.translate()}</Toast.Body>
            </Toast>
          ))}
      </ToastContainer>
    </HelmetProvider>
  );
}

export default App;
